import {Component, OnInit, TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from 'src/app/core/http/user/user.service';
import {CoverageService, Lote} from '../../../../core/http/coverage/coverage.service';
import {Router} from '@angular/router';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {StorageService} from '../../../../core/http/storage/storage.service';

declare const Natal: any;

@Component({
  selector: 'app-dashboard-main',
  templateUrl: './dashboard-main.component.html',
  styleUrls: ['./dashboard-main.component.css']
})
export class DashboardMainComponent implements OnInit {
  modalRef: BsModalRef;

  filterForm: FormGroup;
  lotes: any;
  hectareas: number;
  bolsas: number;
  bolsasRestantes: number;
  isLoading = true;
  rolUser: string;
  cantidadPlanesSiembraAprobados: number;
  page = 0;
  size = 20;
  lastPage = false;
  cantidadPlanesSiembraPendientes: number;
  empresas: any;
  cvrDetail: any;
  distribuidores: any;
  productores: any;
  lote;
  filtro: any;
  cantidadPlanesSiembraConSiniestro: number;
  dropdownSettings: IDropdownSettings = {};
  itemsEmpresa: Array<any> = [];
  itemsDistribuidor: Array<any> = [];
  itemsProductor: Array<any> = [];

  constructor(private filtroBuilder: FormBuilder, private userService: UserService,
              private coverageService: CoverageService, public router: Router,
              private modalService: BsModalService, private storageService: StorageService,
  ) {
    let profile = localStorage.getItem('profile');
    if (profile) {
      // this.rolUser = JSON.parse(profile).role;
      this.rolUser = JSON.parse(profile).usuarios[0].role;
    }
    this.createForm();
  }

  ngOnInit(): void {
    this.distribuidores = [];
    this.productores = [];
    this.empresas = [];
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      searchPlaceholderText: 'Buscar',
      enableCheckAll: false,
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      noDataAvailablePlaceholderText: 'No hay valores disponibles'
    };

    // this.getFilter();
    this.filtro = this.storageService.getStorageObject('filtro','tablero');
    if (!this.filtro) {
      this.onSearch();
    }
    this.getData();
  }

  async getData() {
    switch (this.rolUser) {
      case 'CORPORATE':
        this.empresas = await this.userService.getEmpresas().toPromise();
        break;
      case 'COMPANY':
        this.distribuidores = await this.userService.getDistribuidoresEmpresa(0).toPromise();
        break;
      case 'DISTRIBUTOR':
        this.productores = await this.userService.getProductoresDistribuidor(0).toPromise();
        break;

      default:
    }
    if (this.filtro && this.filtro.empresa !== "" && this.empresas && this.empresas.length) {
      this.empresas.map((item) => {
        if (item.id === this.filtro.empresa) {
          this.itemsEmpresa.push({
            id: item.id,
            name: item.name
          });
          return;
        }
      });
      this.filterForm.get('empresa').setValue(this.itemsEmpresa, {
        onlySelf: true
      });
      this.distribuidores = await this.userService.getDistribuidoresEmpresa(this.filtro.empresa).toPromise();
    }

    if (this.filtro && this.filtro.distribuidor !== "" && this.distribuidores && this.distribuidores.length) {
      this.distribuidores.map((item) => {
        if (item.id === this.filtro.distribuidor) {
          this.itemsDistribuidor.push({
            id: item.id,
            name: item.name
          });
          return;
        }
      });
      this.filterForm.get('distribuidor').setValue(this.itemsDistribuidor, {
        onlySelf: true
      });
      this.productores = await this.userService.getProductoresDistribuidor(this.filtro.distribuidor).toPromise();
    }

    if (this.filtro && this.filtro.productor !== "" && this.productores && this.productores.length) {
      this.productores.map((item) => {
        if (item.id === this.filtro.productor) {
          this.itemsProductor.push({
            id: item.id,
            name: item.name
          });
          return;
        }
      });
      this.filterForm.get('productor').setValue(this.itemsProductor, {
        onlySelf: true
      });
    }

    this.onSearch();
  }


  onSearch() {
    const filtro = {
      empresa: this.filterForm.value.empresa && this.filterForm.value.empresa.length ? this.filterForm.value.empresa[0].id : '',
      distribuidor: this.filterForm.value.distribuidor && this.filterForm.value.distribuidor.length ? this.filterForm.value.distribuidor[0].id : '',
      productor: this.filterForm.value.productor && this.filterForm.value.productor.length ? this.filterForm.value.productor[0].id : '',
      registrosPorPagina: 10
    };

    this.storageService.setStorageObject('filtro','tablero', filtro);
    this.loadPage();
  }

  onDeSelectDistribuidores() {
    this.reset();
    this.storageService.deleteStorageObject('filtro','tablero');
  }


  getDistribuidores() {
    this.filterForm.get('distribuidor').setValue('');
    this.filterForm.get('productor').setValue('');
    this.distribuidores = [];
    this.productores = [];
    let id = 0;
    if (this.filterForm.value.empresa && this.filterForm.value.empresa.length) {
      id = this.filterForm.value.empresa[0].id;
    }
    this.userService.getDistribuidoresEmpresa(id)
      .subscribe(res => {
        this.distribuidores = res;
      }, () => {
        Natal.Notification.error(
          'Error',
          'Se produjo un error al cargar los distribuidores.',
          false, '', 8000
        );
      });
  }

  limpiarDistribuidor() {
    this.productores = [];
    this.filterForm.get('productor').setValue('');
  }


  getProductores() {
    this.filterForm.get('productor').setValue('');
    this.productores = [];
    let idDistribuidor = 0;
    if (this.filterForm.value.distribuidor && this.filterForm.value.distribuidor.length) {
      idDistribuidor = this.filterForm.value.distribuidor[0].id;
    }
    this.userService.getProductoresDistribuidor(idDistribuidor)
      .subscribe(res => {
        this.productores = res;
      }, () => {
        Natal.Notification.error(
          'Error',
          'Se produjo un error al cargar los productores.',
          false, '', 8000
        );
      });
  }

  loadPage() {
    this.hectareas = 0;
    this.cantidadPlanesSiembraPendientes = 0;
    this.cantidadPlanesSiembraAprobados = 0;
    this.cantidadPlanesSiembraConSiniestro = 0;
    this.bolsas = 0;

    const params = {
      empresa: this.filterForm.value.empresa && this.filterForm.value.empresa.length ? this.filterForm.value.empresa[0].id : '',
      distribuidor: this.filterForm.value.distribuidor && this.filterForm.value.distribuidor.length ? this.filterForm.value.distribuidor[0].id : '',
      productor: this.filterForm.value.productor && this.filterForm.value.productor.length ? this.filterForm.value.productor[0].id : '',
    };

    this.coverageService.getAllDashboard(params)
      .subscribe(res => {
        this.lotes = res;
        if (this.lotes || this.lotes.length) {
          this.lotes.map((lote) => {
            if (lote.estado === 'APROBADO') {
              this.cantidadPlanesSiembraAprobados++;
              this.hectareas += lote.hectareas;
              this.bolsas += Number(lote.cantidadInsumo);
            } else if (lote.estado === 'PENDIENTE') {
              this.cantidadPlanesSiembraPendientes += 1;
              this.hectareas += lote.hectareas;
              this.bolsas += Number(lote.cantidadInsumo);
            }

            if (lote.siniestros.length > 0) {
              this.cantidadPlanesSiembraConSiniestro++;
            }
          });

          if (this.filterForm.get('productor').value != '') {
            const productor = this.productores.find(p => p.id == Number(this.filterForm.get('productor').value));
            if( productor){
              this.bolsasRestantes = productor.cantidadBolsasMaxima - this.bolsas;
            }

          }

        }
      }, () => {
        Natal.Notification.error(
          'Error',
          'Se produjo un error al obtener los datos.',
          false, '', 8000
        );
      });
  }

  private createForm() {
    this.filterForm = this.filtroBuilder.group({
      empresa: [''],
      distribuidor: [''],
      productor: [''],

    });
    switch (this.rolUser) {
      case 'CORPORATE':
        this.filterForm.get('empresa').setValidators([Validators.required]);
        break;
      case 'COMPANY':
        this.filterForm.get('distribuidor').setValidators([Validators.required]);
        break;
      case 'DISTRIBUTOR':
        this.filterForm.get('productor').setValidators([Validators.required]);
        break;
      default:
    }

  }

  reset() {
    this.filterForm.get('empresa').setValue('');
    this.filterForm.get('distribuidor').setValue('');
    this.filterForm.get('productor').setValue('');
    this.storageService.deleteStorageObject('filtro','tablero');
    this.filtro = {};
    this.ngOnInit();
  }


  updateState(status: String, lote: Lote) {
    const body = {loteId: lote.id, estado: status};
    this.coverageService.updateState(body, lote.establecimiento.id).subscribe(res => {
      window.location.reload();
      Natal.Notification.success(
        'Exito',
        `El lote se encuentra en estado: ${status}.`,
        false, '', 8000);
    }, err => {
      Natal.Notification.error(
        'Error',
        err.error.data,
        false, '', 8000);
    });
  }

  loadMore() {
    if (!this.isLoading && !this.lastPage) {
      this.page++;
      this.loadPage();
    }
  }

  goCoverageEditForm(establecimiento) {
    this.router.navigate(['/shell/coverage/form', establecimiento.id]);
  }


  openAprobarModal(template: TemplateRef<any>, lote) {
    this.lote = lote;
    this.modalRef = this.modalService.show(template);
  }

  openAprobarModal2(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  openRechazarModal(template: TemplateRef<any>, lote) {
    this.lote = lote;
    this.modalRef = this.modalService.show(template);
  }

  getBolsas() {
    const profile = JSON.parse(localStorage.getItem('profile'));
    return profile.selectUsuario?.bolsas?.filter(element => element.campaniaId == profile.campania.id);
  }

  getBolsasUtilizadas(cultivo: string) {
    let cantidad = 0;

    if (this.lotes) {
      const lotesCultivo = this.lotes.filter(element => element.tipoCultivo === cultivo);

      lotesCultivo.forEach(element => {
        cantidad += element.cantidadInsumo;
      });

    }

    return cantidad;
  }
}

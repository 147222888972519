import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() {
  }

  setStorageObject(tipo: string, nombre: string, object: any) {
    let elementStorage = JSON.parse(localStorage.getItem(tipo)) || {};
    if (elementStorage && `${elementStorage}.${nombre}`) {
      elementStorage[nombre] = object;
    } else {
      elementStorage[nombre] = object;
    }
    localStorage.setItem(tipo, JSON.stringify(elementStorage));
  }

  getStorageObject(tipo: string, nombre: string) {
    let elementStorage = JSON.parse(localStorage.getItem(tipo)) || {};
    if (elementStorage && `${elementStorage}.${nombre}`) {
      elementStorage = elementStorage[nombre];
    } else {
      elementStorage = {};
    }
    return elementStorage;
  }

  getStorage(param) {
    let elementStorage = '';
    elementStorage = JSON.parse(localStorage.getItem(param));
    if (elementStorage === null) {
      elementStorage = '';
    }
    return elementStorage;
  }

  removeItemStorage(param: string) {
    localStorage.removeItem(param);
  }

  deleteStorageObject(tipo: string, nombre: string) {
    let elementStorage = JSON.parse(localStorage.getItem(tipo)) || {};
    delete elementStorage[nombre];
    localStorage.setItem(tipo, JSON.stringify(elementStorage));
  }
}

<app-toolbar title="TABLERO DE CONTROL"></app-toolbar>
<form class="filter-section " novalidate [formGroup]="filterForm" (ngSubmit)="onSearch()">
  <div class="row">
    <div class="form-group col-md-3" *ngIf="rolUser === 'CORPORATE' ">
      <label for="empresa">Empresa</label>
      <ng-multiselect-dropdown id="empresa" [placeholder]="'Seleccione'" formControlName="empresa"
        [settings]="dropdownSettings" (onSelect)="getDistribuidores()" (onDeSelect)="onDeSelectDistribuidores()"
        [data]="empresas">
      </ng-multiselect-dropdown>
    </div>
    <div class="form-group col-md-3" *ngIf="rolUser === 'COMPANY' || rolUser ==='CORPORATE' ">
      <label for="distribuidor">Distribuidor</label>
      <ng-multiselect-dropdown id="distribuidor" [placeholder]="'Seleccione'" formControlName="distribuidor"
        [settings]="dropdownSettings" (onSelect)="getProductores()" (onDeSelect)="limpiarDistribuidor()"
        [data]="distribuidores">
      </ng-multiselect-dropdown>
    </div>
    <div class="form-group col-md-4"
      *ngIf="rolUser === 'COMPANY' || rolUser === 'DISTRIBUTOR' || rolUser ==='CORPORATE' ">
      <label for="productor">Productor</label>
      <ng-multiselect-dropdown id="productor" [placeholder]="'Seleccione'" formControlName="productor"
        [settings]="dropdownSettings" [data]="productores">
      </ng-multiselect-dropdown>

    </div>
    <div class="col-md-1">
      <div class="form-group">
        <br>
        <button type="submit" class="btn btn-primary btn-block" [disabled]="!filterForm.valid">Buscar</button>
      </div>
    </div>
    <div class="col-md-1">
      <div class="form-group">
        <br>
        <button type="button" class="btn btn-secondary btn-block" (click)="reset()"
          [disabled]="!filterForm.valid">Limpiar
        </button>
      </div>
    </div>
    
  </div>
  



</form>
<div class="main-panel table-responsive" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollUpDistance]="1.5"
  [infiniteScrollThrottle]="100" (scrolled)="loadMore()" alwaysCallback="true">
 
  <div class="row">
    <div class="col-md-3 resumen">
      <div class="form-group bordes">
        <!-- <p class="mr"><b>Total de Bolsas: {{bolsas ? bolsas:0}}</b></p> -->
        <p class="mr" *ngIf="bolsasRestantes>=0"><b>Bolsas restantes: {{ bolsasRestantes ? bolsasRestantes : 0 }}</b>
        </p>

        <p class="mr"><b>Total de
            negocios: {{ cantidadPlanesSiembraAprobados ? cantidadPlanesSiembraAprobados : 0 }}</b></p>
        <p class="mr"><b>Negocios pendiente de
            gestionar: {{ cantidadPlanesSiembraPendientes ? cantidadPlanesSiembraPendientes : 0 }}</b></p>
        <p class="mr"><b>Total de negocios con
            denuncia: {{ cantidadPlanesSiembraConSiniestro ? cantidadPlanesSiembraConSiniestro : 0 }}</b></p>

      </div>


    </div>
  </div>
  <div class="row insumos" *ngIf="(rolUser === 'COMPANY' || rolUser === 'DISTRIBUTOR') && lotes">
    <p class="title">Insumos</p>

      <table class="table table-bordered table-cultivos">
        <tr>
          <th>Cultivo</th>
          <th>Campaña</th>
          <th>Insumo</th>
          <th>Cantidad Asignada</th>
          <th>Cantidad Utilizada</th>
        </tr>
        <tr *ngFor="let bolsa of getBolsas()">
          <td>{{ bolsa.tipoCultivo }}</td>
          <td>{{ bolsa.campania.anio }}</td>
          <td>{{ bolsa.insumo }}</td>
          <td>{{ bolsa.cantidad | number: '1.2-2' }}</td>
          <td>{{ getBolsasUtilizadas(bolsa.tipoCultivo) | number: '1.2-2' }}</td>
        </tr>
      </table>
  </div>
  <div class="row planes">
    <p class="title">Planes de Siembra</p>
    <table aria-describedby="Gatherers table" class="table table-striped table-bordered">
      <thead>
        <tr>
          <th scope="col" class="text-center">Productor</th>
          <th scope="col" class="text-center">Localidad</th>
          <th scope="col" class="text-center">Establecimiento</th>
          <th scope="col" class="text-center">Hectareas</th>
          <th scope="col" class="text-center">Cultivo</th>
          <th scope="col" class="text-center">Cant. Insumo</th>
          <th scope="col" class="text-center">Insumo</th>
          <th scope="col" class="text-center">Estado</th>
          <th scope="col" class="text-center">Acciones</th>
  
        </tr>
      </thead>
  
      <tbody>
        <tr *ngFor="let lote of lotes">
          <td class="text-center">{{ lote.establecimiento.user.name }}</td>
          <td class="text-center">{{ lote.establecimiento.localidadCercana }}</td>
          <td class="text-center">{{ lote.establecimiento.nombre }}</td>
          <td class="text-center">{{ lote.hectareas }}</td>
          <td class="text-center">{{ lote.tipoCultivo }}</td>
          <td class="text-center">{{ lote.cantidadInsumo }}</td>
          <td class="text-center">{{ lote.insumo }}</td>
  
  
          <td *ngIf="lote.estado == 'APROBADO'" class="text-center aprobado">Aprobado</td>
          <td *ngIf="lote.estado == 'PENDIENTE'" class="text-center pendiente">Pendiente</td>
          <td *ngIf="lote.estado == 'RECHAZADO'" class="text-center rechazado">Rechazado</td>
          <td class="text-center accion">
  
            <button *ngIf="lote.estado === 'PENDIENTE'" class="btn btn-link" data-toggle="tooltip" title="Aprobar"
              (click)="openAprobarModal(aprobarModal, lote)">
              <span class="glyphicon glyphicon-ok"></span>
            </button>
            <button *ngIf="lote.estado === 'PENDIENTE'" class="btn btn-link" data-toggle="tooltip" title="Rechazar"
              (click)="openRechazarModal(rechazarModal, lote)">
              <span class="glyphicon glyphicon-remove"></span>
            </button>
            <button *ngIf="lote.estado === 'APROBADO'" class="btn btn-link boton" data-toggle="tooltip"
              title="Modificar datos" (click)="goCoverageEditForm( lote.establecimiento)">
              <span class="glyphicon glyphicon-edit"></span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

 
</div>

<ng-template #aprobarModal>
  <div class="modal-body text-center">
    <p>¿Esta seguro que desea aprobar el plan de siembra?</p>
    <button type="button" class="btn btn-default" (click)="updateState('APROBADO', lote)">Si</button>
    <button type="button" class="btn btn-primary" (click)="modalRef.hide()">No</button>
  </div>
</ng-template>
<ng-template #rechazarModal>
  <div class="modal-body text-center">
    <p>¿Esta seguro que desea rechazar el plan de siembra?</p>
    <button type="button" class="btn btn-default" (click)="updateState('RECHAZADO', lote)">Si</button>
    <button type="button" class="btn btn-primary" (click)="modalRef.hide()">No</button>
  </div>
</ng-template>